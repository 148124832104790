@import "styles/colors";
@import "styles/breakpoints";

.container {
  background-color: $WHITE;
  font-size: 1.2rem;
  margin: 0 auto;
  @include tablet {
    padding: 0 40px;
    @include desktop {
      padding: 0 80px;
    }
  }

  .bannerAd {
    display: none;
    @include desktop {
      display: grid;
      justify-content: center;
      text-align: center;
      text-transform: uppercase;
      font-size: 1.4rem;
      margin-top: 30px;
    }
  }

  .homepageTakeover {
    position: fixed;
    z-index: 1;
    margin: 0 15px;
  }

  .homepageTakeover.left {
    left: 0;
  }

  .homepageTakeover.right {
    right: 0;
  }

  .mobileAd {
    @include desktop {
      display: none;
    }
  }

  .header {
    padding: 10px 30px 15px;
    background-color: $SAND;
    color: $GREY_MEDIUM;
    font-size: 1.4rem;

    @include tablet {
      padding-top: 45px;
      padding-bottom: 45px;
      padding-left: 80px;
      display: flex;
    }
  }

  .mainGrid {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    @include desktop {
      max-width: 150vh;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  .column {
    position: sticky;
    padding: 30px 0 0;
    @include desktop {
      padding: 60px 0 40px;
    }
  }

  .trendingStoriesContainer {
    margin-bottom: 30px;
    @include desktop {
      display: none;
      margin: 0;
    }

    .sectionHeading {
      @include desktop {
        margin-top: 0px;
      }
    }
  }

  .featuredArticlesContainer {

    .featuredArticlesInnerContainer {
      @include desktop {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  .moreFeaturedArticlesContainer {

    .featuredArticlesInnerContainer {
      display: flex;
      flex-direction: row;
      overflow-x: auto;
      @include desktop {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }

      > div {
        width: 90%;
        @include tablet {
          width: 60%;
        }
        @include desktop {
          width: 100%
        }
      }
    }
  }

  .latestArticlesContainer {

    .latestArticlesInnerContainer {
      @include desktop {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
  }

  .desktopSideWidgets {
    grid-row: 1;
    grid-column: 2;

    .trendingStoriesContainer {
      display: none;
      max-width: 500px;
      @include desktop {
        display: revert;
      }
    }
  }

  .printHeroCard {
    margin: 30px 0;
  }

  .signUpWidget {
    @include desktop {
      margin-top: 30px;
    }
  }

  .widgets {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    @include desktop {
      padding-left: 60px;
    }
  }

  .bottomWidgets {
    grid-column: span 2;
    padding-bottom: 50px;
  }

  .sectionHeading {
    margin: 40px 0 15px;
    padding: 0 30px;
    font-family: Domaine Display Narrow Test, serif;
    font-weight: 600;
    font-size: 3.2rem;
    @include tablet {
      padding: 0;
    }
  }

  .loadMoreStoriesButtonContainer {
    display: none;
    text-align: center;
    margin-top: 40px;
    @include tablet {
      display: revert;
    }
  }
}

.container.homepageTakeover {
  .homepageTakeover {
    display: none;
  }

  @include desktop {
    padding: 0 200px;

    .homepageTakeover {
      display: revert;
    }
    .hero {
      padding-left: 200px;
      padding-right: 200px;
    }
    .trendingNow {
      padding-left: 200px;
      padding-right: 200px;
    }
  }
}

.videoPlayer {
  width: 100%;
  @include tablet {
    max-height: 360px;
    margin: 0 auto;
    @include desktop {
      max-height: revert;
    }
  }
}
